<template>
  <v-card class="elevation-12 pa-3 pa-md-5 mx-auto">
      <h1 class="display-1 text-center font-weight-light">Login</h1>
            
              <v-card-text>
                <v-form @submit.prevent="login()" id="check-login-form">
                  <v-text-field
                    label="Email"
                    name="email"
                    prepend-icon="mdi-account"
                    type="text"
                    autocomplete="username"
                    v-model.trim="loginForm.email"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    autocomplete="current-password"
                    v-model.trim="loginForm.password"
                  ></v-text-field>
                </v-form>
                <p v-if="error">Error!</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn type="submit" color="primary" block form="check-login-form">Login</v-btn>
              </v-card-actions>
            </v-card>
</template>

<script>
export default {
    data() {
        return {
            loginForm: {
                email: '',
                password: ''
            },
            error: false
        }
    },
    methods: {
    login() {
        this.$store.dispatch('login', {
            email: this.loginForm.email,
            password: this.loginForm.password
        }) 
    }
}

}
</script>

<style>

</style>